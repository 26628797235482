import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, main } from '../../content/osiagniecia'
import { useLangContext } from '../../context/lang.context'
import Intro from '../../components/Intro'
import Main from '../../components/Main'
import ImageSection from '../../components/ImageSection'

const Osiagniecia = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/osiagniecia.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      image1: file(absolutePath: { regex: "/images/achievements/forbes2023.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [JPG]
          )
        }
      }
      image2: file(
        absolutePath: { regex: "/images/achievements/gryfpomorski2023.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [JPG]
          )
        }
      }
      image3: file(
        absolutePath: { regex: "/images/achievements/forbes2019.jpeg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [JPG]
          )
        }
      }
      #image4: file(
      #  absolutePath: { regex: "/images/achievements/solidnypracodawca.jpeg/" }
      #) {
      #  childImageSharp {
      #    gatsbyImageData(
      #      layout: FULL_WIDTH
      #      quality: 100
      #      placeholder: TRACED_SVG
      #      formats: [JPG]
      #    )
      #  }
      #}
      image4: file(
        absolutePath: { regex: "/images/achievements/gazelebiznesu.jpeg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [JPG]
          )
        }
      }
      image5: file(
        absolutePath: { regex: "/images/achievements/gryfgospodarczy.jpeg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [JPG]
          )
        }
      }
      gptw2023pl: file(
        absolutePath: { regex: "/images/achievements/gptw2023pl.png/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
      gptw2023en: file(
        absolutePath: { regex: "/images/achievements/gptw2023en.png/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Przeglądaj',
      en: 'Explore',
    },
    action: 'SCROLL',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button }}
        image={query.image.childImageSharp}
        // position='top'
      />
      <Main h={1} title={main.title[lang]} desc={main.body[lang]}>
          {main.sections?.map(({ title, desc }, id) => {
              const isGptw2023Image = id === 5;

              const imageKey = isGptw2023Image
                  ? lang === 'pl' ? 'gptw2023pl' : 'gptw2023en'
                  : `image${id + 1}`;

              const image = query[imageKey]?.childImageSharp;

              return (
                  <ImageSection
                      html
                      noeffect
                      h={2}
                      s={3}
                      key={id}
                      title={title[lang]}
                      desc={desc?.[lang]}
                      image={image}
                  />
              );
          })}

      </Main>
    </Layout>
  )
}

export default Osiagniecia
